import React, { Component } from 'react';

import styled, { createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';

import { LeftNav } from './nav';
import { GlobalStyle } from '../style';

const PageStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
  }

  body {
    padding-left: 220px; // LeftNav
    background: #f0f2f5;
  }

`;

const PageDiv = styled.div`
`;

const LeftNavWrap = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 220px;
  height: 100%;

  color: rgba(0,0,0,.65);
  background: #C88242;
`;


const LogoWrap = styled.div`
  padding: 25px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
`;

const Main = styled.main`
  padding: 20px;
  margin: 20px;
  background: #fff;

  > * :first-child {
    margin-top: 0;
  }
`;

export class Page extends Component {
  render() {
    const { navItems } = this.props;
    return (
      <PageDiv>
        <GlobalStyle/>
        <PageStyle/>
        <LeftNavWrap>
          <LogoWrap>
            <img alt='Oho' src='/logo.svg' />
            <div>API Documentation</div>
          </LogoWrap>
          <LeftNav navItems={navItems}/>
        </LeftNavWrap>
        <Main>
          {this.props.children}
        </Main>
      </PageDiv>
    );
  }
}

Page.propTypes = {
  children: PropTypes.node,
  navItems: PropTypes.any,
};

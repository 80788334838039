import _ from 'lodash';

export class Schema {
  constructor(src) {
    this.src = src;
  }

  getOperation(path, method) {
    method = method.toLowerCase();
    path = path.replace(/\/:[^/]+/g, k => `/{${k.substr(2)}}`);
    const op = (this.src.paths[path] || {})[method.toLowerCase()];
    if (!op) {
      return null;
    }
    op.path = path;
    op.method = method.toUpperCase();
    return op;
  }

  getResolvedOperation(path, method) {
    const operation = _.cloneDeep(this.getOperation(path, method));
    if (operation === null) {
      return null;
    }

    operation.parameters = operation.parameters.map(({ schema, ...rest }) => ({
      ...rest,
      schema: this.resolveRefs(schema),
    }));

    operation.responses = Object.keys(operation.responses).map(statusCode => ({
      ...operation.responses[statusCode],
      schema: this.resolveRefs(operation.responses[statusCode].schema),
      statusCode,
    }));
    return operation;
  }

  findRef(ref) {
    const parts = ref.split('/');
    if (parts.length < 2 || parts.shift() !== '#') {
      return null;
    }

    let working = this.src;

    for (let idx = 0; idx < parts.length; idx += 1) {
      const pathPart = parts[idx];
      if (!Object.prototype.hasOwnProperty.call(working, pathPart)) {
        return null;
      }
      working = working[pathPart];
    }
    return {
      refName: parts.pop(),
      ...working,
    };
  }

  resolveRef(ref) {
    const found = this.findRef(ref);
    if (!found) {
      return null;
    }

    return this.resolveRefs(found);
  }

  resolveRefs(srcObject) {
    if (!srcObject) {
      return null;
    }
    let obj = _.cloneDeep(srcObject);

    const { $ref: ref, ...rest } = obj;

    if (ref) {
      obj = {
        ...this.findRef(ref),
        ...rest,
      };
    }

    const required = obj.required || [];

    if (obj.properties) {
      const newProps = Object.keys(obj.properties).map(k => ({
        ...this.resolveRefs(obj.properties[k]),
        name: k,
        required: required.indexOf(k) > -1,
      }));
      obj.properties = newProps;
    }

    if (obj.items) {
      obj.items = this.resolveRefs(obj.items);
    }
    return obj;
  }
}

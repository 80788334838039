import React, { Component } from 'react';
import { Schema } from './schema';

const SchemaContext = React.createContext(null);

export const withSchemaOperation = WrappedComponent => {
  class WithSchemaOperation extends Component {
    render() {
      const schema = this.context;
      const { path, method, ...rest } = this.props;
      if (!schema) {
        return <div>NO SCHEMA</div>;
      }

      const operation = schema.getResolvedOperation(path, method);

      if (!operation) {
        return <div><p>Missing Operation <code>{method} {path}</code>.</p></div>;
      }
      return <WrappedComponent operation={operation} {...rest}/>;
    }
  }
  WithSchemaOperation.contextType = SchemaContext;
  return WithSchemaOperation;
};

export class SchemaProvider extends Component {
  render() {
    const { children, schema: schemaDef } = this.props;
    const schema = new Schema(schemaDef);
    return (
      <SchemaContext.Provider value={schema}>
        {children}
      </SchemaContext.Provider>
    );
  }
}

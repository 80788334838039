import React from 'react';
import { Redirect, Route, BrowserRouter as Router } from 'react-router-dom';

import content from './content.json';

// TODO: Load from the API server
import schema from './schema.json';

import { SchemaProvider } from './schema/context';

import { Page } from './elements/page';
import { EntityPage } from './pages/entity';
import { GeneralPage } from './pages/general';

function Content({ content }) {
  if (content.type === 'entity') {
    return <EntityPage src={content}/>;
  }
  return <GeneralPage src={content.blocks}/>;
}

function App() {
  const navItems = content.map(({ path, content: { title } }) => ({ path, title }));

  const routes = content.map(({ path, content }) => (
    <Route key={path} path={path} exact render={routeProps => (
      <Content content={content}/>
    )}
    />
  ));

  return (
    <Router>
      <SchemaProvider schema={schema}>
        <Page navItems={navItems}>
          <Redirect from="/" to="/overview"/>
          {routes}
        </Page>
      </SchemaProvider>
    </Router>
  );
}

export {
  App,
};

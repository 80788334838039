import { createGlobalStyle } from 'styled-components';

export const colors = {
  orange: '#e56a54',
  red: '#f04e37',
  blue: '#595478',
  teal: '#7cccbf',
  grey: '#e3e1db',

  // Found in guide but not explicit
  lines: '#6f7272',

  // OFF BRAND
  bg: '#001529',
  blue_lt: '#a59ae6',
  blue_dk: '#191724',
  yellow: '#ffd866',
  green: '#8ebd5a',
  purple: '#ab9df2',
  white: '#fff',
};


export const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'Dosis';
    font-weight: bold;
    src: url('/fonts/dosis.bold.ttf');
  }
  
  @font-face {
    font-family: 'Dosis';
    font-weight: 500;
    src: url('/fonts/dosis.semibold.ttf');
  }

  @font-face {
    font-family: 'Dosis';
    font-weight: 200;
    src: url('/fonts/dosis.light.ttf');
  }

  @font-face {
    font-family: 'Open Sans';
    src: url('/fonts/open-sans.regular.ttf');
  }

  body {
    font-family: 'Open Sans', sans-serif;
    font-size: 12pt;
  }

  h1 {
    font-family: 'Dosis', sans-serif;
    font-weight: bold;
    font-size: 34pt;
  }

  h2 {
    font-family: 'Dosis', sans-serif;
    font-size: 15pt;
    font-weight: 500;
  }

  h3 {
    font-family: 'Dosis', sans-serif;
    font-size: 13pt;
    font-weight: bold;
  }

  p, li, td {
    font-size: 10pt;
  }

  blockquote {
    padding: 10px;
    margin: 0px;
    border-left: solid 5px ${colors.orange};
    background: ${colors.grey};
    & > *:first-child {
      margin-top: 0
    }
    & > *:last-child {
      margin-bottom: 0
    }
  }

`;

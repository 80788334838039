import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import { Code } from './code';

const resolvePath = p => {
  if (_.startsWith(p, '/public/')) {
    return p.substr(7);
  }
  if (_.endsWith(p, '.md')) {
    return p.substr(0, p.length - 3);
  }
  return p;
};

const reParts = /(`[^`]+`|\*\*[^*]+\*\*|\*[^*]+\*|!?\[[^\]]+\]\([^)]+\))/g;
// `code`
// **bold**
// *italic*
// ![image](href)
// [link](href)
const reLink = /^\[([^\]]+)\]\(([^)]+)\)/;

export const markdown = raw => {
  const parts = raw.split(reParts);
  return parts.map((p, idx) => {
    if (_.startsWith(p, '`')) {
      return <code key={idx}>{p.substr(1, p.length - 2)}</code>;
    }
    if (_.startsWith(p, '**')) {
      return (<strong key={idx}>{p.substr(2, p.length - 3)}</strong>);
    }
    if (_.startsWith(p, '*')) {
      return (<em key={idx}>{p.substr(1, p.length - 2)}</em>);
    }
    if (_.startsWith(p, '!')) {
      const [, text, href] = reLink.exec(p.substr(1));
      const [filePath, qsString] = href.split('?');
      let style = {};

      if (_.endsWith(filePath, '.svg')) {
        style = {
          maxWidth: '1000px',
          width: '100%',
        };
      }

      if (qsString) {
        style = Object.assign(style, queryString.parse(qsString));
      }
      return (<img key={idx} style={style} src={resolvePath(filePath)} alt={text}/>);
    }
    if (_.startsWith(p, '[')) {
      const [, text, href] = reLink.exec(p);
      const resolved = resolvePath(href);
      if (_.startsWith(resolved, 'http://') || _.startsWith(resolved, 'https://')) {
        return (<a key={idx} href={resolved} rel="noopener noreferrer" target="_blank">{text}</a>);
      }
      return (<Link key={idx} to={resolved}>{text}</Link>);
    }
    return <span key={idx}>{p}</span>;
  });
};

export const Heading = ({ level, children, ...rest }) => {
  const HeadingTag = `h${level}`;
  return (<HeadingTag {...rest}>{children}</HeadingTag>);
};
Heading.propTypes = {
  level: PropTypes.number,
  children: PropTypes.node,
};

export class MDElements extends Component {
  render() {
    const { src } = this.props;
    return src.map((childSrc, idx) => (<MDInline key={idx} src={childSrc}/>));
  }
}

export class MDInline extends Component {
  render() {
    const { src } = this.props;
    switch (src.type) {
      case 'p':
        return (<p>{markdown(src.content)}</p>);
      case 'code':
        return (
          <Code className={src.language}>
            {src.lines.join('\n')}
          </Code>
        );
      case 'heading':
        return (<Heading level={src.level}>{markdown(src.content)}</Heading>);
      case 'list': {
        const items = src.items.map((item, itemIdx) => (
          <li key={itemIdx}>
            <MDElements src={item}/>
          </li>
        ));

        if (src.orered) {
          return (<ol>{items}</ol>);
        }
        return (<ul>{items}</ul>);
      }
      case 'blockquote':
        return (
          <blockquote>
            <MDElements src={src.content}/>
          </blockquote>
        );

      default:
        return (<p>UNKNOWN {src.type}</p>);
    }
  }
}

MDInline.propTypes = {
  src: PropTypes.object,
};


export const MDBlock = ({ title, content, mdChildren, hLevel = 1 }) => {
  const childChildren = mdChildren.map((c, idx) => (
    <MDBlock
      key={idx}
      mdChildren={c.children}
      content={c.content}
      title={c.title}
      hLevel={hLevel + 1 }
    />
  ));

  const contentChildren = content.map((c, idx) => <MDInline key={idx} src={c}/>);

  return (
    <Fragment>
      <Heading level={hLevel}>{markdown(title)}</Heading>
      {contentChildren}
      {childChildren}
    </Fragment>
  );
};
const elementPropType = {
  title: PropTypes.string,
  hLevel: PropTypes.number,
  content: PropTypes.array,
  mdChildren: PropTypes.array,
};

MDBlock.propTypes = elementPropType;

import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';

import { colors } from '../style';


const PropsTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  td, th {
    font-weight: normal;
    border: solid 1px ${colors.lines};
    padding: 3px 5px;
    text-align: left;
    font-size: 12pt;
  }

  th {
  }

  thead th {
    font-weight: bold;
    background-color: ${colors.grey};
  }
`;


const EndpointHeaderStyle = styled.div`
  background: ${colors.bg};
  border-radius: 5px;
  padding: 10px;
  color: ${colors.white};
`;

const Method = styled.code`
  color: ${colors.orange};
`;

const Path = styled.code`
  color: ${colors.teal};
`;

const PathParam = styled.code`
  color: ${colors.green};
`;


class EndpointHeader extends Component {
  render() {
    const {
      method,
      path,
    } = this.props;

    const pathParts = path.split('/').map((p, idx, arr) => ([
      (p.substr(0, 1) === '{' ? <PathParam key={idx}>{p}</PathParam> : p),
      (idx !== arr.length - 1 ? '/' : null),
    ]));

    return (
      <EndpointHeaderStyle>
        <Method>{method}</Method> <Path>{pathParts}</Path>
      </EndpointHeaderStyle>
    );
  }
}

const ExpandBar = styled.div`
  display: flex;
  background-color: ${colors.blue};
  border-radius: 5px;
  &.open {
    border-radius: 5px 5px 0 0;
  }
  line-height: 20px;
  padding: 5px;
  color: ${colors.white};
  > span {
    flex-grow: 2;
  }
  > button {
    cursor: pointer;
    box-sizing: border-box;
    height: 20px;
    line-height: 20px;
    padding: 0;
    border: solid 1px ${colors.orange};
    border-radius: 5px;
    width: 30px;
    color: #fff;
    font-weight: bold;
    background: ${colors.orange};
  }
`;

class Expandable extends Component {
  state = {
    expanded: false,
  }

  open = () => {
    this.setState({ expanded: true });
  }

  close = () => {
    this.setState({ expanded: false });
  }

  render() {
    const { expanded } = this.state;
    const { title } = this.props;
    if (!expanded) {
      return (
        <ExpandBar>
          <span>{title}</span>
          <button onClick={this.open}>+</button>
        </ExpandBar>
      );
    }
    return (
      <div>
        <ExpandBar className='open'><span>{title}</span><button onClick={this.close}>-</button></ExpandBar>
        {this.props.children}
      </div>
    );
  }
}

EndpointHeader.propTypes = {
  method: PropTypes.string,
  path: PropTypes.string,
};

const formatRef = n => n.replace(/^(Detail|Deep|Base)/, '').replace(/(Load|Dump)$/, '');

const DefinedTermStyle = styled.div`
  cursor: pointer;
  position: relative;
  > .definition {
    display: none;
    position: absolute;
    
    // Loosely based on ant.design
    background: #fff;
    bottom: 30px;
    width: 300px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  &:hover > .definition {
      display: block;
  }
  > span {
  text-decoration: underline;
  }
`;

class DefinedTerm extends Component {
  render() {
    const { children, definition } = this.props;
    return (
      <DefinedTermStyle>
        <div className='definition'>
          {definition}
        </div>
        <span>
          {children}
        </span>
      </DefinedTermStyle>
    );
  }
}

class SchemaObject extends Component {
  render() {
    const { properties = [] } = this.props;

    return (
      <PropsTable>
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {properties.map((r, idx) => {
            const itemSchema = r.type === 'array' ? r.items : r;

            if (itemSchema.type === 'object' && itemSchema.refName === 'BaseModel') {
              return (
                <tr key={idx}>
                  <td><code>{r.name}</code></td>
                  <td>
                    <DefinedTerm definition={(
                      <Fragment>
                        <p>
                          An object pointing to another entity, with only 'id' defined
                        </p>
                        <code>{'{"id": integer}'}</code>
                      </Fragment>
                    )}
                    >
                      <code>
                        {r.type === 'array' && '[]'}identity
                      </code>
                    </DefinedTerm>
                  </td>
                  <td>
                    {r.description}
                  </td>
                </tr>
              );
            }

            if (itemSchema.type === 'object') {
              return (
                <tr key={idx}>
                  <th>{r.name}</th>
                  <td colSpan="2">
                    {r.description}
                    <Expandable title={formatRef(itemSchema.refName || 'object')}>
                      <SchemaObject {...itemSchema}/>
                    </Expandable>
                  </td>
                </tr>
              );
            }
            return (
              <tr key={idx}>
                <td><code>{r.name}</code></td>
                <td><code>{r.type === 'array' && '[]'}{itemSchema.type}</code></td>
                <td>{r.description}</td>
              </tr>
            );
          })}
        </tbody>
      </PropsTable>
    );
  }
}


class SchemaResponse extends Component {
  render() {
    const { description, schema, statusCode } = this.props;
    return (
      <div style={{ marginBottom: '30px' }}>
        <div style={{ marginBottom: '20px' }}>
          <code>{statusCode}</code>: {description}
        </div>

        {schema && <SchemaObject {...schema}/>}

      </div>
    );
  }
}


class FlatProps extends Component {
  render() {
    const { params, showRequired } = this.props;
    return (
      <PropsTable>
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {params.map(({ name, type, description, required }) => (
            <tr key={name}>
              <th>{name} {required && showRequired ? '*' : ''}</th>
              <td>{type}</td>
              <td>{description}</td>
            </tr>
          ))}
        </tbody>
      </PropsTable>
    );
  }
}

export class Operation extends Component {
  render() {
    const { parameters, responses, path, method } = this.props;

    const qsParams = parameters.filter(p => p.in === 'query');
    const pathParams = parameters.filter(p => p.in === 'path');
    const body = parameters.find(p => p.in === 'body');

    return (
      <div>
        <EndpointHeader method={method} path={path}/>

        { pathParams.length > 0 && (
          <Fragment>
            <h4>Parameters</h4>
            <FlatProps params={pathParams} />
          </Fragment>
        )}

        { qsParams.length > 0 && (
          <Fragment>
            <h4>Query</h4>
            <FlatProps params={qsParams} showRequired={true} />
          </Fragment>
        )}

        {body && (
          <Fragment>
            <h4>Request Body</h4>
            <SchemaObject {...body.schema} />
          </Fragment>
        )}

        { responses.length > 0 && (
          <h4>Responses</h4>
        )}
        { responses.map(resp => (
          <SchemaResponse key={resp.statusCode} {...resp}/>
        )) }
      </div>
    );
  }
}

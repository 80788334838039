import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';

const NavElement = styled.nav`
  color: #212721;
  font-size: 14pt;
  padding: 0;
  display: block;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    > li {
      margin: 0;
      padding: 0;
      font-weight: 400;
      a, .groupTitle {
        color: inherit;
        display: block;
        padding: 10px 20px;
        text-decoration: none;
        transition: background-color .15s cubic-bezier(.215,.61,.355,1);
      }
      a {
        &.active {
          background: #FFF;
          color: #212721;
          font-weight: 900;
        }
        &:hover {
          color: #212721;
        }
        cursor: pointer;
      }
      ul {
        padding-left: 10px;
        background: #E4C1A1;
      }
    }
  }
`;


export class NavGroup extends Component {
  render() {
    const { title, items } = this.props;
    return (
      <Fragment>
        { title && (<div className='groupTitle'>{title}</div>)}

        <ul>
          {items.map(({ type, path, items, title }, idx) => (type === 'group'
            ? (<li key={idx}><NavGroup items={items} title={title}/></li>)
            : (<li key={idx}><NavLink activeClassName="active" to={path}>{title}</NavLink></li>)))}
        </ul>
      </Fragment>
    );
  }
}

const fillNames = (items, navItems) => items.map(i => (
  i.type === 'group'
    ? { ...i, items: fillNames(i.items, navItems) }
    : { ...i, title: i.title || (navItems.find(({ path }) => path === i.path) || {}).title }
));

export class LeftNav extends Component {
  render() {
    const structure = [
      { type: 'page', path: '/overview', title: 'Overview' },
      { type: 'page', path: '/mechanics', title: 'Mechanics' },
      { type: 'page', path: '/authentication' },
      { type: 'page', path: '/webhook' },
      { type: 'page', path: '/entity-overview' },
      {
        type: 'group',
        path: '/entities/',
        items: this.props.navItems.filter(({ path }) => _.startsWith(path, '/entities/')),
      },
      // { type: 'page', path: '/longpoll' },
    ];

    return (
      <NavElement>
        <NavGroup items={fillNames(structure, this.props.navItems)}/>
        <ul>
          <li>
            <a href="https://app.weareoho.com/swagger/" rel="noopener noreferrer" target="_blank">Swagger UI</a>
          </li>
        </ul>
      </NavElement>
    );
  }
}

LeftNav.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.node,
  })),
};

import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { MDElements } from '../elements/markdown';
import {
  Operation,
} from '../elements/schema';
import { withSchemaOperation } from '../schema/context';


const PageWrap = styled.div`
`;

const Clear = styled.div`
  clear: both;
`;


@withSchemaOperation
class OperationWrapper extends Component {
  render() {
    const { operation, intro } = this.props;

    return (
      <section>
        {intro}
        <Operation {...operation} />
      </section>
    );
  }
}

export class EntityContent extends Component {
  render() {
    const { src: { title, content: contentIn } } = this.props;
    const content = [...contentIn];


    let operationDef = null;
    if (content.length && content[0].type === 'code' && content[0].language === 'endpoint') {
      const contentCode = content.shift();
      const [method, path] = contentCode.lines[0].split(' ');
      operationDef = { method, path };
    }

    const contentElements = (<MDElements src={content}/>);

    if (!operationDef) {
      return (
        <section>
          <h3>{title}</h3>
          {contentElements}
        </section>
      );
    }

    return (
      <section>
        <h3>{title}</h3>
        <OperationWrapper {...operationDef} intro={contentElements}/>
      </section>
    );
  }
}

export class EntitySection extends Component {
  render() {
    const { src: { title, sub, content } } = this.props;
    return (
      <section>
        <h2>{title}</h2>
        <MDElements src={content}/>
        { sub.map((src, idx) => <EntityContent key={idx} src={src}/>) }
      </section>
    );
  }
}

export class EntityPage extends Component {
  render() {
    const { src: { title, intro, sections } } = this.props;

    const sectionElements = sections.map((src, idx) => <EntitySection key={idx} src={src}/>);

    return (
      <PageWrap>
        <h1>{title}</h1>
        <MDElements src={intro}/>
        {sectionElements}
        <Clear/>
      </PageWrap>
    );
  }
}

EntityPage.propTypes = {
  src: PropTypes.any,
};

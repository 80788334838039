import Highlight from 'react-highlight';
import Styled from 'styled-components';

import { colors } from '../style';

export const Code = Styled(Highlight)`
  display: block;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 10pt;
  color: ${colors.white};
  line-height: 1.5em;
  background: ${colors.blue_dk};
  .hljs-tag,
  .hljs-keyword,
  .hljs-selector-tag,
  .hljs-literal,
  .hljs-strong,
  .hljs-name {
    color: ${colors.red};
  }

  .hljs-code {
    color: ${colors.white};
  }

  .hljs-class .hljs-title {
    color: ${colors.white};
  }

  .hljs-attribute,
  .hljs-symbol,
  .hljs-regexp,
  .hljs-link {
    color: ${colors.blue_lt};
  }

  .hljs-string,
  .hljs-bullet,
  .hljs-subst,
  .hljs-title,
  .hljs-section,
  .hljs-emphasis,
  .hljs-type,
  .hljs-built_in,
  .hljs-builtin-name,
  .hljs-selector-attr,
  .hljs-selector-pseudo,
  .hljs-addition,
  .hljs-variable,
  .hljs-template-tag,
  .hljs-template-variable {
    color: ${colors.teal};
  }

  .hljs-comment,
  .hljs-quote,
  .hljs-deletion,
  .hljs-meta {
    color: ${colors.grey}; 
  }

  .hljs-keyword,
  .hljs-selector-tag,
  .hljs-literal,
  .hljs-doctag,
  .hljs-title,
  .hljs-section,
  .hljs-type,
  .hljs-selector-id {
    //font-weight: bold;
  }
`;

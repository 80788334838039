import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { MDInline } from '../elements/markdown';

export class GeneralPage extends Component {
  render() {
    const { src } = this.props;
    const children = src.map((src, idx) => (
      <MDInline key={idx} src={src}/>
    ));


    return (
      <div>
        {children}
      </div>
    );
  }
}

GeneralPage.propTypes = {
  src: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
  })),
};
